import styled, { css } from 'styled-components'
import { rem } from '../../../utils/common'
import { fonts, merchantThemes } from '../../Variable'
import { device } from '../../Variable'

export const InputWrapperStyle = styled.label`
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
	width: 100%;
`
export const Error = styled.div`
	padding-top: ${rem(3)};
	font-size: ${rem(12)};
	line-height: 1;
	color: ${({ theme }) => theme.color.redOne};
	${(p) => p.isMain && css`
		position: absolute;
		white-space: nowrap;
		@media (${device.mobileL}) {
			top: ${rem(44)};
			left: ${rem(-4)};
		}
		@media (${device.mobileS}) {
			left: ${rem(-65)};
		}
	`}
	${(p) => p.isCustomized && css`
		@media (${device.mobileM}) {
			position: absolute;
			top: ${rem(48)};
		}
	`}
`
export const InputStyle = styled.input`
	display: block;
	width: 100%;
	min-width: 0;
	flex: 0 0 100%;
	max-width: 100%;
	padding:${rem(0, 22)};
	height: ${rem(50)};
	border: 1px solid ${({ theme }) => theme.color.grayFour};
	//border-radius: ${rem(10)};
	background-color: transparent;
	font-family: ${fonts.futura};
	${(p) => p.error && css`
		border: 1px solid ${({ theme }) => theme.color.redOne};
	`}
	${(p) => p.theme.name === merchantThemes.marysia && css`
		font-family: ${fonts.futura};
	`}
	${(p) => p.theme.name === merchantThemes.woodwood && css`
		font-family: ${fonts.WWBeta8Regular};
	`}
	${(p) => p.theme.name === merchantThemes.aycane && css`
		font-family: ${fonts.IBMPlex};
	`}
	${(p) => p.theme.name === merchantThemes.bymalina && css`
		font-family: ${fonts.AvenirBook};
	`}
	${(p) => p.theme.name === merchantThemes.royal_design && css`
		font-family: ${fonts.openSans};
	`}
	${(p) => p.theme.name === merchantThemes.henrylloyd && css`
		font-family: ${fonts.Gotham};
	`}
	${(p) => p.theme.name === merchantThemes.chimi && css`
		font-family: ${fonts.AmericaExpandedRegular};
	`}
	${(p) => p.theme.name === merchantThemes.musto && css`
		font-family: ${fonts.openSans};
	`}
	${(p) => p.theme.name === merchantThemes.ogoship && css`
		font-family: ${fonts.usual};
	`}
	${(p) => p.theme.name === merchantThemes.hh && css`
		font-family: ${fonts.dinPro};
	`}
	${(p) => p.theme.name === merchantThemes.hh_ww && css`
		font-family: ${fonts.dinPro};
	`}
	${(p) => p.theme.name === merchantThemes.toteme && css`
		border: 1px solid ${({ theme }) => theme.color.primary};
		padding:${rem(0, 15)};
		font-family: ${fonts.MonumentGrotesk};
		border-radius: ${rem(32)};
		height: ${rem(33)};
		font-size: ${rem(14)};
	`}
	
	transition: all 0.3s;
	font-size: ${rem(16)};
	line-height: ${rem(18)};
	&::placeholder {
		font-size: ${rem(16)};
		line-height: ${rem(48)};
		
		${(p) => p.theme.name === merchantThemes.toteme && css`
			font-size: ${rem(14)};
		`}
		
		${(p) =>
			p.placeholderNotBold &&
			css`
				font-weight: 400;
			`}
	}
	&::-webkit-input-placeholder {
		line-height: normal;
	}
	@media (${device.mobileXL}) {
		padding:${rem(0, 16)};

	}
}
`
